<template>
  <LMap
    :center="[9.386079, 80.374889]"
    v-model:zoom="zoom"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
    :zoomAnimation="true"
    :options="mapOptions"
  >
    <LTileLayer :url="url"></LTileLayer>
    <LMarker v-for="(archive, index) in archives" :latLng="archive.gps_point" v-bind:key="index">
      <LTooltip>
        <p><b>Created: </b> {{ archive.createdAt }}</p>
        <p v-if="archive.district"><b>District: </b>{{ archive.district }}</p>
        <p v-if="archive.divisional_secretariat">
          <b>Divisional Secretariat: </b>{{ archive.divisional_secretariat }}
        </p>
        <p v-if="archive.gn_division"><b>GN Division: </b>{{ archive.gn_division }}</p>
      </LTooltip>
    </LMarker>
  </LMap>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {LMap, LTileLayer, LMarker, LTooltip} from '@vue-leaflet/vue-leaflet';

export default {
  props: {
    archives: Array,
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      zoom: 9,
      minZoom: 1,
      maxZoom: 20,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {
        zoomSnap: true,
      },
    };
  },
};
</script>
